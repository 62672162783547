export function calculateReadTime(words, images) {
	const wordsPerMinute = 265
	const secondsPerImage = 9

	let time = words / wordsPerMinute

	time = time + (images * secondsPerImage) / 60
	return `${Math.ceil(time)}min read`
}

export function capitalizeFirstChar(str) {
	if (str.length === 0) return str // Handle empty string
	return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1)
}

export function capitalCase(str: string) {
	return str.toLowerCase().replace(/\b\w/g, function (char) {
		return char.toUpperCase()
	})
}

export function createFormNote(data) {
	let n = ''

	Object.keys(data).forEach((k) => {
		const formattedKey = k.replaceAll('_', ' ').replaceAll('[]', '')
		if (Array.isArray(data[k])) {
			n += `${formattedKey}: \n`
			data[k].forEach((item, i) => {
				if (item) {
					n += `${item}\n`
				}
				if (i === data[k].length - 1) {
					n += '\n'
				}
			})
		} else if (data[k]) {
			n += `${formattedKey}: \n${data[k]}\n\n`
		}
	})

	return n.replace(/\n+$/, '')
}

export function formDataToObj(formData) {
	const object = {}
	formData.forEach((value, key) => {
		// Reflect.has in favor of: object.hasOwnProperty(key)
		if (!Reflect.has(object, key)) {
			object[key] = value
			return
		}
		if (!Array.isArray(object[key])) {
			object[key] = [object[key]]
		}
		object[key].push(value)
	})
	return object
}

export function processQueryStrings(queryObject) {
	if (Object.keys(queryObject).length > 0) {
		let query = ''
		let index = 0
		for (const queryKey in queryObject) {
			query = `${query}${queryKey}=${queryObject[queryKey]}`
			query = index < Object.keys(queryObject).length - 1 ? `${query}&` : query
			index++
		}
		return query
	}
	return null
}

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export function stripHtml(content) {
	const html = new DOMParser().parseFromString(content, 'text/html')
	return html.body.textContent || ''
}

export function compare(objectA, objectB, key) {
	return objectA[key] > objectB[key] ? 1 : objectB[key] > objectA[key] ? -1 : 0
}

export function getURLParamString(fullPath) {
	return fullPath.split('?')[1]
}

// Theory Resource functions
export function hasFilter(resources) {
	return (
		resources.findIndex(
			(resource) =>
				window.location.hash ===
				`#${resource.content.title.replace(/\s+/g, '-').toLowerCase()}`
		) !== -1
	)
}

export function resourceFiltered(item) {
	return (
		window.location.hash ===
		`#${item.content.title.replace(/\s+/g, '-').toLowerCase()}`
	)
}

export function checkIfType(type, name) {
	if (type) {
		return type.some((typeObject) => {
			if (typeObject.name === name) return true
		})
	}
	return false
}
