import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'
import facebookPixelPlugin from '@/utils/facebookPixelAnalyticsPlugin.js'

export function useAnalytics() {
	const analytics = Analytics({
		app: 'molonglo',
		plugins: [
			googleTagManager({
				containerId: 'GTM-K7MBF7G'
			}),
			facebookPixelPlugin({
				pixelId: '3144903305742613'
			})
		]
	})

	return {analytics}
}
